.wrap {
  position: relative;
}

.buttonRow {
  margin-top: 16px;
}
.iconWrap {
  margin-bottom: 16px;
}
.iconWrap img {
  width: 100%;
}

.text {
  position: absolute;
  max-height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.box-alignment--bottom-left {
  bottom: 0;
  left: 0;
}
.box-alignment--bottom-right {
  bottom: 0;
  right: 0;
}
.box-alignment--top-left {
  top: 0;
  left: 0;
}
.box-alignment--top-right {
  top: 0;
  right: 0;
}

.vertical-alignment--top {
  justify-content: flex-start;
}
.vertical-alignment--center {
  justify-content: center;
}
.vertical-alignment--bottom {
  justify-content: flex-end;
}
