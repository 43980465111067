.wrap {
  background: theme('colors.inputBackground');
  padding: 24px 16px;
  margin-top: 40px;
}

.linkGroup {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ccc;
}

.linkGroupHeadline {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 4px;
}

.linkGroupLinksWrap {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}

.link {
  display: inline-flex;
  line-height: 24px;
  position: relative;
}

.link {
  margin-right: 10px;
  padding-right: 10px;
}

.link:after {
  content: '';
  position: absolute;
  right: 0;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background: theme('colors.primary');
}

.link:last-child {
  margin-right: 0;
  padding-right: 0;
}

.link:last-child:after {
  content: none;
}

.shareHeadline {
  font-weight: 600;
  text-align: center;
  font-size: 12px;
}

.copyrightRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.copyright {
  font-size: 12px;
}

.socialMediaOuterWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.socialMediaWrap {
  display: flex;
  margin: 16px auto 32px;
  align-items: center;
  column-gap: 32px;
}

.socialMediaItem {
  width: 28px;
  height: 28px;
  flex-grow: 0;
}

.socialMediaItem img {
  width: 100%;
}

.logoWrap {
  width: 100px;
}

.logoWrap img {
  width: 100%;
}
