.wrap {
  background: theme('colors.primary');
  color: #fff;
  display: flex;
  padding: 20px;
  column-gap: 16px;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: 3;
}
.logoWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.headline {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}
.subHeadline {
  text-transform: uppercase;
}
