.wrapper {
  padding: 0 !important;
  margin: 0 !important;
}
.grid {
  margin: 0 !important;
  min-height: auto !important;
}

.main {
  min-height: calc(100vh - 180px);
}
