.description {
  margin-top: theme('space.4');
}

.subscriptionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: theme('space.4');
}

.logo {
  max-width: 100px;
}
