.teaserWrap {
  background: theme('colors.gray200');
  color: #fff;
  margin: 20px 0;
  display: flex;
  column-gap: 8px;
  overflow: hidden;
}
.teaserWrap--hasCenterDivider {
  column-gap: 0;
}
.teaserWrap--hasCenterDivider .col {
  border-right: 4px solid #fff;
  border-left: none;
}
.teaserWrap--hasCenterDivider .col + .col {
  border-right: none;
  border-left: 4px solid #fff;
}
.teaserWrap--isContentLeft {
  flex-direction: row-reverse;
}
.teaserWrap--isContentLeft.teaserWrap--hasCenterDivider .col {
  border-left: 4px solid #fff;
  border-right: none;
}
.teaserWrap--isContentLeft.teaserWrap--hasCenterDivider .col + .col {
  border-left: none;
  border-right: 4px solid #fff;
}
.col {
  width: 50%;
  flex-shrink: 0;
  flex-grow: 0;
}
.iconWrap {
  margin-bottom: 12px;
}
.contentWrap {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.headline {
  font-size: 22px;
  line-height: 23px;
  font-weight: 800;
  margin-bottom: 16px;
  white-space: pre-line;
  hyphens: auto;

  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.linkWrap {
  font-size: 12px;
  font-weight: 800;
}
.imageWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image {
  height: 100%;
  object-fit: contain;
}
.image--objectFit-cover {
  object-fit: cover;
}
.link {
  display: inline-flex;
  column-gap: 8px;
}
