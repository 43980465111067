.tabs {
  display: flex;
  gap: theme('spacing.10');
  padding: 0 20px;
}

.tabContent {
  margin-top: theme('spacing.8');
}

.tabGrid {
  min-height: auto;
}

.wrap-tab-modifier {
  padding: 0 !important;
}

.wrap-tab-modifier > div {
  padding: 0 !important;
  margin: 0 !important;
  min-height: auto !important;
}

.loadingIndicator {
  padding: 0 20px;
}
