.row {
  display: flex;
  column-gap: 16px;
}

.imageWrap {
  flex-grow: 0;
  flex-shrink: 0;
}

.content {
  margin-top: -5px;
}
