.itemWrap {
  margin-bottom: 24px;
  margin-top: 24px;
}

.item + .item {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #eee;
}

.description {
  color: theme('colors.gray400');
  font-size: 12px;
}
