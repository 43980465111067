.wrap {
  color: #fff;
  padding: 20px 20px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  background: theme('colors.primary');
}

.alignment--left {
  text-align: left;
}
.alignment--center {
  text-align: center;
}
.alignment--right {
  text-align: right;
}
