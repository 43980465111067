.navigationWrap {
  background: #fff;
  border-top: 1px solid theme('colors.primary');
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 100;
}
.navigation {
  display: flex;
  justify-content: space-between;
  max-width: 540px;
  margin: 0 auto;
}
