.navigationItem {
  position: relative;
  width: 20%;
  display: flex;
  justify-content: center;
}

.label {
  color: theme('colors.fontColor');
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}
.navigationItem--active {
  background: theme('colors.primary');
  .label {
    color: theme('colors.greenNeon');
  }
}
.linkWrap {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  padding: 8px 4px 24px;
  cursor: pointer;
}

.iconWrap {
  height: 40px;
  width: 40px;
  position: relative;
}
.iconWrap > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}

.counterWrap {
  position: relative;
}

.counter {
  background: #e30813;
  font-size: 12px;
  font-weight: 500;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  right: 0;
  color: #fff;
  position: absolute;
}
